<template>
  <vx-card title="DN With and Without PO Reference">
    <vs-tabs>
      <vs-tab label="Open With Ref">
        <open-with></open-with>
      </vs-tab>
      <vs-tab label="Open Without Ref">
        <open-without></open-without>
      </vs-tab>
      <vs-tab label="Pending">
        <div class="tab-text">
          <pending></pending>
        </div>
      </vs-tab>
      <vs-tab label="Reject">
        <div class="tab-text">
          <reject></reject>
        </div>
      </vs-tab>
      <vs-tab label="Approved">
        <div class="tab-text">
          <approved></approved>
        </div>
      </vs-tab>
      <vs-tab label="Applied">
        <div class="tab-text">
          <applied></applied>
        </div>
      </vs-tab>
      <vs-tab label="Paid">
        <div class="tab-text">
          <paid></paid>
        </div>
      </vs-tab>
      <vs-tab label="Reversed">
        <div class="tab-text">
          <reversed></reversed>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import OpenWith from "./open_with_ref";
import OpenWithout from "./open_without_ref";
import Pending from "./pending";
import Reject from "./reject";
import Approved from "./approved";
import Applied from "./applied";
import Paid from "./paid";
import Reversed from "./reversed";
export default {
  components: {
    OpenWith,
    OpenWithout,
    Pending,
    Approved,
    Applied,
    Paid,
    Reject,
    Reversed
  },
};
</script>